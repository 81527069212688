.App {
  text-align: center;

}

/* styles.css */
.PopoverTrigger {
  background-color: white;
  border-radius: 4px;
}

.PopoverContent {
  border-radius: 4px;
  padding: 20px;
  width: 260px;
  background-color: white;
}

.PopoverArrow {
  fill: white;
}

.sel {
  margin-left: 10px;
}

/* Grid樣式 */
.grid-full {
  width: 100%;
  margin: 0 auto;
}

.grid-80-center {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.button-nowrap {
  white-space: nowrap;
  overflow: hidden;
  display: flex;
}

.card-train-approaching {
  background: rgba(200, 0, 0, 0.3);
}


header {
  position: fixed;
  top: 0rem;
  width: 100%;
  height: 3rem; /* 假設 header 高度為 4rem */
  background-color: #0e0e0e;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 4rem; /* 假設 footer 高度為 4rem */
  background-color: #0e0e0e;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.flex-grow {
  padding-top: 3.5rem; /* 預留 header 高度 */
  padding-bottom: 3rem; /* 預留 footer 高度 */
}
